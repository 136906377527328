import { permissionMessages } from '../utils/constants';
import ArticleParagraph from './ArticleParagraph';
import ArticleUpdate from './ArticleUpdate';

const ArticleViewSecondary = ({ axios, article, images, date }) => {
    return (
        <section id={`a${article.seq}`} className='article-primary' >
            <header>
                <h2>{article.title}</h2>
                {article.p2R > 0&& <div className='message warning'>{permissionMessages[article.p2R]}</div>}
            </header>

            {article.paragraphs?.map((paragraph, index) =>
                <ArticleParagraph paragraph={paragraph} article={article.id} articleSeq={article.seq} paragraphSeq={index} seq={`a${article.seq}p${index}`} images={images.filter(f => f.seq === article.seq && f.paragraph === index)} date={date} key={index} />
            )}
            <ArticleUpdate article={article} seq={article.seq} date={date} />
        </section>
    )
}

export default ArticleViewSecondary