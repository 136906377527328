import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { ArticlePublished } from './index.js';
import { setModalPosition } from '../utils/common';
import { rapi } from '../utils/constants.js';

const Tag = ({ setMessage }) => {
  const { id } = useParams();
  const [tags, setTags] = useState([]);
  const [trigger, setTrigger] = useState(0);
  const [tagAdding, setTagAdding] = useState('');
  const [tagSelected, setTagSelected] = useState('');
  const [articleListLaunched] = useState(false)

  window.addEventListener('click', () => {
    if (articleListLaunched) return

  })

  useEffect(() => {
    rapi.get(`/tag/article/${id}`)
      .then(res => {
        if (res.status !== 200) {
          console.log(`${res.status} : ${res.statusText}`);
          return false;
        }
        setTags(res.data.list);

        setTimeout(() => {
          const tagEls = document.querySelectorAll(`.tag-group`)
          const tagSelectedIndex = Array.from(tagEls).findIndex(f => f.children[0].dataset.tag === sessionStorage.getItem('tagSelected'))
      
          if (tagSelectedIndex === -1) return
      
          const modalContainer = document.querySelector(`.tags > .modal-container`)
          modalContainer.classList.remove('hide')
          setModalPosition(tagEls[tagSelectedIndex])
        }, 1500);
        return true

      }).catch(error => {
        console.log(error);
        return false
      });


  }, [trigger, id])

  const tagLinkHandler = (e) => {
    e.preventDefault()

    const modalContainer = document.querySelector(`.tags > .modal-container`)
    modalContainer.classList.remove('hide')
    // modalContainer.style.top = `${e.target.parentNode.offsetTop + e.target.parentNode.offsetHeight + 5}px`
    setModalPosition(e.currentTarget)

    setTagSelected(e.target.dataset.tag)
    sessionStorage.setItem('tagSelected', e.target.dataset.tag)
    sessionStorage.setItem('keyword', '')
    sessionStorage.setItem('page', 1)
    sessionStorage.setItem('articleModalPosA', e.target.parentNode.offsetTop)
  };

  const tagDeleteHandler = (e) => setTags(tags.filter(f => f !== e.target.dataset.tag));

  const tagAddHandler = (e) => {
    setTagAdding(e.target.value);

    if (![';', ',', 'ArrowRight'].includes(e.nativeEvent.data)) return false;
    if (tags.includes(tagAdding)) {
      console.log('dup!');
      setTagAdding('');
      return false;
    }

    tags.push(tagAdding);
    setTagAdding('');
  };

  const tagPasteHandler = (e) => {
    navigator.clipboard.readText()
      .then(text => {
        const tagsToAdd = text.split(';');
        tagsToAdd.forEach((t) => {
          if (!tags.includes(t)) {
            tags.push(t)
            // setTagAdding(t);
          }
        })
        setMessage('pasted')
        setTags(tags);
      })
      .catch(err => {
        setMessage(`Failed to read clipboard contents:\n ${err}`)
      });
  };

  const tagSaveHandler = () => {
    if (tags.length === 0) return false;

    const request = {
      articleID: id,
      tName: tags.join(';')
    }

    rapi.put(`/tag/bulk/`, request)
      .then(res => {
        if (res.status !== 200) {
          console.log(`${res.status} : ${res.statusText}`)
          return false
        }
        setMessage('tag list updated')
        setTrigger(trigger + 1)
      }).catch(error => {
        console.log(error);
      })

  }

  const tagCopyHandler = () => {
    if (tags.length === 0) return false

    navigator.clipboard.writeText(tags.join(';'))
    setMessage('tags were copied to clipboard')
  }

  return (
    <section className='tags'>
      <button className='tag-group' key='all'>
        <div className='tag' data-tag='전체글' onClick={tagLinkHandler} >전체글</div>
      </button>
      {tags?.map((t, index) =>
        <button className='tag-group' key={index} data-content={'#'}>
          <div className='tag' data-tag={t} onClick={tagLinkHandler} >{t}</div>
          <div className='tag-x' data-tag={t} onClick={tagDeleteHandler} ></div>
        </button>
      )}
      {
        <div className='modal-container hide' >
          <ArticlePublished tag={tagSelected} onModal={true} />
        </div>
      }

      <input type="text" id="tag-add" name="tag-add" placeholder='Tag' value={tagAdding} onChange={tagAddHandler} onPaste={tagPasteHandler} />
      <button type='button' onClick={tagSaveHandler} >Save</button>
      <button type='button' className='icon' onClick={tagCopyHandler} >C</button>
      <button type='button' className='icon' onClick={tagPasteHandler} >P</button>
    </section>
  )
}

export default Tag