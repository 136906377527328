import ArticleImage from './ArticleImage';

const ArticleParagraph = ({ paragraph, article, seq, images, date}) => {
  const imagesAbove = images?.filter(f => ['T', 'L', 'R'].includes(f.alignment));
  const imagesBelow = images?.filter(f => f.alignment === 'B')
  return (
    <>
      {imagesAbove.length > 0 && imagesAbove?.map((image, idx) =>
        <ArticleImage key={image.id} article={article} seq={seq} image={image} date={date} />
      )}
      <p id={seq}>
        {paragraph.replaceAll('\r\n', '\n').split('\n').map((sentence, index) => {
          return (index === 0) ? sentence : [<br key={index} />, sentence]
        })}
      </p>
      {imagesBelow.length > 0 && imagesBelow?.map((image, idx) =>
        <ArticleImage key={image.id} article={article} seq={seq} image={image} date={date} />
      )}
    </>
  )
}

export default ArticleParagraph