import { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { rapi, permissionMessages } from '../utils/constants';
import styles from '../css/ArticleView.module.css';
import {ArticleParagraph, ArticleViewSecondary, ArticleUpdate, Tag, ArticleLink } from '../components/index.js';
import { Toast } from '../components/Toast';

const ArticleView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [articlePrimary, setArticlePrimary] = useState('');
  const [articles2nd, setArticles2nd] = useState([]);
  const [images, setImages] = useState([]);
  // const [tags, setTags] = useState([]);
  const [links, setLinks] = useState([])
  const [trigger, setTrigger] = useState(0);
  const [message, setMessage] = useState('loaded');
  // access_token

  // load articles unpublished
  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    if (access_token === undefined || access_token === null ) {
      navigate('/login')
      return
    }

    rapi.defaults.headers.common = {'Authorization': `Bearer ${access_token}`}
    rapi.get(`article/${id}`).then(res => {
      if (res.status !== 200) {
        console.log(`${res.status} : ${res.statusText}`);
      }

      const result = res.data.result;

      if (result.articlePrimary.id === 0 ) return false

      result.articlePrimary.dateOccurred = result.articlePrimary.dateOccurred.split('T')[0];

      // reconstruct body
      result.articlePrimary.paragraphs = result.articlePrimary.body.replaceAll('\n\n', '\r\n\r\n').split('\r\n\r\n');

      result.articlesSecondary?.map(a => 
        a.paragraphs = a.body.replaceAll('\n\n', '\r\n\r\n').split('\r\n\r\n')
      );
      
      setArticlePrimary(result.articlePrimary);
      setArticles2nd(result.articlesSecondary);
      setImages(result.images);
      // setTags(result.tags);
      setLinks(result.links)

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

    }).catch(error => {
      console.log(error);
      if (error.response.status === 401){
        console.log(error.response.status)
        localStorage.removeItem("access_token");
        localStorage.setItem("nickname", '')
        localStorage.setItem("name", '')
        localStorage.setItem("social", '')

        navigate('/login')
      }
    });

  }, [trigger, id, navigate]);

  const addSecondaryHandler = () => {
    const request = {
      id: articlePrimary.id,
      seq: 0,
      title: 'secondary title',
      p2R: 0,
      body: 'secondary body',
      markup: 0
    }

    rapi.put('/article/secondary', request).then(res => {
      if(res.status !== 200){
        console.log(`${res.status} : ${res.statusText}`);
      }

      alert('secondary article just added');
      setTrigger(res.data.result);
    }).catch(error => {
      console.log(error);
    });
  }

  const publishHandler = () => {
    if (!window.confirm('are you sure?')) return false;

    const request = {
      id: articlePrimary.id,
      publishing: !articlePrimary.isPublished
    };

    rapi.patch(`/article/publish`, request).then(res => {
      if(res.status !== 200){
        console.log(`${res.status} : ${res.statusText}`);
      }

      if (res.data.code !== 200){
        console.log(res.data.message);
        return false;
      }

      alert('Publication status : updated');
      window.location.reload();

    }).catch(error => {
      console.log(error);
    });
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(articlePrimary.title)
    setMessage('title were coppied to clipboard')
  }

  // preloading message
  if (articlePrimary === null || articlePrimary.id === 0) return 'Loading...'; 

  // prevent context menu
  // window.oncontextmenu = function(event) {
  //   event.preventDefault()
  //   event.stopPropagation()
  //   return false;
  // };  

  return (
    <>
      <article className="article-view">
          <header>
              <h1 className='primary'>
                {articlePrimary.title}
                <button type='button' className='icon' onClick={copyToClipboard}>C</button>
                <button type='button' className='icon'>CW</button>
              </h1>
              <time dateTime={articlePrimary.dateOccurred}>{articlePrimary.dateOccurred}</time> 
              {articlePrimary.p2R > 0 && <div className='message warning'>{permissionMessages[articlePrimary.p2R]}</div>}
          </header>
            <section id="a0" className='article-primary' /* ref={drop}*/ >
              {articlePrimary.paragraphs?.map((paragraph, index) => 
                <ArticleParagraph paragraph={paragraph} article={id} articleSeq={0} paragarpahSeq={index} seq={`a0p${index}`} images={images.filter(f => f.seq === 0 && f.paragraph === index)} date={articlePrimary.dateOccurred} key={`p${index}`} />
              )}
              <ArticleUpdate article={articlePrimary} />
            </section>
            {articles2nd?.map((a2, index) => 
                <ArticleViewSecondary article={a2} images={images.filter(f => f.seq === a2.seq /*&& f.paragraph === index*/ )} date={articlePrimary.dateOccurred} key={`s${index}`} />
            )}

          <footer>
            <div>
              <span className={styles.author}>{articlePrimary.author} </span>
            </div>
          </footer>
          <ArticleLink articleId={id} links={links} setMessage={setMessage} />
          <div className='control' >
              <button type='button' className='right warning' onClick={publishHandler} >Publish</button>
              <button type='button' className='right' onClick={addSecondaryHandler} >Add Secondary</button>
            </div>
      </article>
      <Tag setMessage={setMessage} />
      <Toast message={message} />
    </>
  )
}

export default ArticleView