import { useEffect, useState } from 'react'
import { rapi } from '../utils/constants'

const ArticleLink = ({ articleId, links, setMessage }) => {
  const [books, setBooks] = useState([])
  useEffect(() => {
    if (links === null || links === undefined ) return

    const isbnList = links.reduce((acc, link) => {
      if (link.cat === 10) acc.push(link.code)
      return acc
    }, [])

    if (isbnList.length === 0) return

    rapi.get(`book/list/${isbnList.join('-')}`).then(res => {
      if (res.status !== 200){
        console.log(`${res.status} : ${res.statusText}`)
        return 
      }

      setBooks(res.data)
    }).catch(err => console.log(err))
  }, [articleId, links])

  return (
    <aside className={books.length > 0 ? 'references book' : 'empty'}>
      { books.map((book, index) => (
        <a href={book.url} target="_blank" rel="noreferrer noopener" key={index} >
          <img src={book.thumbnail} className='left' alt={book.title} />
          <span className='title' >{book.title}</span>
          <span className='author'>{book.authors[0]}</span>
          <span className='publisher'>{book.publisher}</span>
          <span className='pubDate' >{book.datetime.substring(0,4)}</span>
          <span className='price'>{new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(book.price)}</span>
        </a>
        ))
      }
    </aside>
  )
}

export default ArticleLink