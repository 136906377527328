import { useEffect, useState } from 'react'
import { ArticlePublished } from './index.js';
import { setModalPosition } from '../utils/common';
import { rapi } from '../utils/constants.js';

const TagList = ({ count }) => {
  const [tags, setTags] = useState([]);
  const [tagCount, setTagCount] = useState(count);
  const [tagSelected, setTagSelected] = useState('');

  useEffect(() => {
    rapi.get(`/tag?count=${tagCount}`)
      .then(res => {
        if (res.status !== 200) {
          console.log(`${res.status} : ${res.statusText}`);
        }

        setTags(res.data.list);
      }).catch(error => {
        console.log(error);
      });
  }, [tagCount]);

  const tagHandler = (e) => {
    e.preventDefault()

    const tagSelectedBefore = document.querySelector(`.tags > button.selected`)
    if (tagSelectedBefore !== null){
      tagSelectedBefore.classList.remove('selected');
    }

    e.currentTarget.classList.add('selected')

    const root = document.documentElement

    const tags = document.querySelector(`.tags`)
    const modalContainer = document.querySelector(`.tags > .modal-container`)
    modalContainer.classList.remove('hide')
    // modalContainer.style.top = `${e.currentTarget.offsetTop + e.currentTarget.offsetHeight}px`

    setModalPosition(e.currentTarget)
    setTagSelected(e.currentTarget.dataset.tag)
    sessionStorage.setItem('tagSelected', e.currentTarget.dataset.tag)
    sessionStorage.setItem('keyword', '')
    sessionStorage.setItem('page', 1)
    sessionStorage.setItem('articleModalPos', e.currentTarget.offsetTop + e.currentTarget.offsetHeight + 5)

  };

  const tagAllHandler = (e) => {
    setTagCount(tagCount === 20 ? 0 : 20);
  };

  return (
    <section>
      <header>
        {tagCount > 0 ? `Recent Tags` : `All Tags`}
        <button type='button' onClick={tagAllHandler} className='right' >{tagCount === 0 ? '최근 태그만 보기' : '전체 보기'}</button>
      </header>
      <nav className='tags'>
        <button type='button' data-tag='전체글' onClick={tagHandler}>
          <h3 className='L3'>전체글</h3>
        </button>
        {tags.map((t, idx) => (
          <button type='button' key={idx} data-tag={t.tName} onClick={tagHandler} >
            <h3 className={`L${t.lv}`} data-content={'#'} >{t.tName}</h3>
          </button> 
        )) }
        {
          <div className='modal-container hide' >
            <ArticlePublished tag={tagSelected} onModal={true} />
          </div>
        }
        
      </nav>
    </section>
  )
}

export default TagList