export const setModalPosition = (tagSelected) => {
  const root = document.documentElement

  const modalContainer = document.querySelector(`.tags > .modal-container`)
  modalContainer.style.top = `${tagSelected.offsetTop + tagSelected.offsetHeight + 5}px`

  if (window.innerWidth > 960) {
    root.style.setProperty('--modal-pointer-left', 30)
    modalContainer.style.transform = 'none'
    modalContainer.style.left = `${tagSelected.offsetLeft}px`
    const tagsEl = document.querySelector(`.tags`)
    const rightSpace = (window.innerWidth - tagsEl.offsetWidth) * 0.5
    if (tagSelected.offsetLeft > tagsEl.offsetWidth + rightSpace - 660) {
      modalContainer.style.left = `${tagsEl.offsetWidth + rightSpace - 660}px`
      root.style.setProperty('--modal-pointer-left', tagSelected.offsetLeft + tagSelected.offsetWidth * 0.5 - tagsEl.offsetWidth - rightSpace + 650)
    }
  } else {
    modalContainer.style.setProperty('transform', 'translate(-50%, 0)')
    modalContainer.style.setProperty('left', '50%')
    root.style.setProperty('--modal-pointer-left', tagSelected.offsetLeft + tagSelected.offsetWidth * 0.2)
  }

}