import { useState, useEffect } from 'react'
import { rapi } from '../utils/constants';
import Book from './Book';

const ArticleUnpublished = () => {
  const [articles, setArticles] = useState([]);
  const [trigger, setTrigger] = useState('');

  const [postable, setPostable] = useState(Boolean)

  // load articles unpublished
  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    setPostable(access_token != null);
    rapi.defaults.headers.common = {'Authorization': `Bearer ${access_token ?? ""}`}
  
    rapi.get('/article/list-unpublished').then(res => {
      if(res.status !== 200){
        console.log(`${res.status} : ${res.statusText}`);
      }
      setArticles(res.data.list);
    }).catch(error => {
      console.log(error);
    });

  }, [trigger]);

  // Quick add submit
  const articleAddQuickSubmit = (e) => {
    e.preventDefault();

  
    const request = {
      dateOccurred: e.target.date.value,
      title: e.target.title.value,
      refCat: parseInt(e.target.refCat.value),
      refCode: e.target.refCode.value
    };

    rapi.post('/article/quick', request).then(res => {
      if (res.status !== 200){
        console.log(`${res.status} : ${res.statusText}`);
      }

      setTrigger(trigger + 1);

      e.target.title.value = ''; // reset title
      e.blur();

    }).catch(err => console.log(err));

    return false;
  }

  // today configuration for default value of date input box
  const today = (new Date()).toISOString().substring(0,10);

  // renderer
  return (
    <section className='article-list unpublished'>
        <header>Articles Unpublished</header>
        <nav className='list'>
          {articles.map((a) => (
              <article className='list-item' key={a.id} >
                <a href={`/article/${a.id}`} data-id={a.id} >
                  <time dateTime={a.dateOccurred}>{a.dateOccurred.split('T')[0].substring(5,10).replace('-','.')}</time> 
                  <span className='title'>{a.title}</span>
                </a>
              </article>
          ))}
        </nav>
        <form onSubmit={articleAddQuickSubmit} id="article-quick" >
            <input type="date" id="date" name="date" defaultValue={today} />
            <input type="text" id="title" name="title" />
            <input type="hidden" id="refCat" name="refCat" defaultValue="0" />
            <input type="hidden" id="refCode" name="refCode" />
            <button type='submit' id="article-button" disabled={!postable} >Quick Add</button>
        </form>
        <Book />
    </section>
  )
}

export default ArticleUnpublished