import { useState } from 'react'
import { rapi } from '../utils/constants';

const Book = () => {
  // Modal control
  const [modalActive, setModalActive] = useState(false);
  const bookModalHandler = (e) => {
    setModalActive(!modalActive);
  }

  // Search List Modal control
  const [listModalActive, SetListModalActive] = useState(false);
  let timeoutId = 0;
  const listModalHandler = (e) => {
    if (['Backspace', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) return false;

    clearTimeout(timeoutId);
    timeoutId = setTimeout(bookHandler, 500);
  }

  const [booksSearched, SetBookSearched] = useState([]);
  const [bookActiveIndex, setBookActiveIndex] = useState(0);

  // event handler : click to book button
  const bookHandler = (e) => {
    const query = document.querySelector('#search').value;
    // console.log(`searching for ${query}`);

    rapi.get(`/book?query=${query}`)
      .then(res => {
        if (res.status !== 200) {
          console.log(`${res.status} : ${res.statusText}`);
        }
        SetBookSearched(res.data.items);
        setBookActiveIndex(0);

      }).catch(error => {
        console.log(error);
      });

    SetListModalActive(!listModalActive);
  }

  // event handler : select book from searched list
  const bookSelectedHandler = (e) => {

    const bookselected = typeof(e) == 'object' ? e.currentTarget.dataset : booksSearched[e];

    const titleEl = document.querySelector("#title");
    const refCatEl = document.querySelector('#refCat');
    const refCodeEl = document.querySelector('#refCode');

    const subTitleBegin = bookselected.title.indexOf('(');
    const title = subTitleBegin > 0 ? bookselected.title.substring(0, subTitleBegin - 1) : bookselected.title;
    titleEl.value = `『${title}』 ${bookselected.author}`;
    refCatEl.value = "10";
    refCodeEl.value = `${bookselected.isbn}`;

    SetListModalActive(false);
    setModalActive(false);
    setBookActiveIndex(0);
  }

  const bookKeydown = (e) => {
    if (!['Enter', 'ArrowDown', 'ArrowUp'].includes(e.key)) return false;

    // booksSearched.length
    let idx = 0;
    switch(e.key){
      case 'Enter':
        bookSelectedHandler(bookActiveIndex);
        setBookActiveIndex(0);
        break;
      case 'ArrowDown':
        idx = Math.min(bookActiveIndex + 1, booksSearched.length - 1);
        setBookActiveIndex(idx);
        break;
      case 'ArrowUp':
        idx = Math.max(bookActiveIndex - 1, 0);
        setBookActiveIndex(idx);
        break;
      default: 
        break;
    }
  }

  return (
    <div className='book-area'>
      <button type='button' id="book-button" onClick={bookModalHandler} >Book</button>
      {
        modalActive &&

        <div className='modal-container' onKeyDown={bookKeydown}>
          <section className='modal'>
            <header>
              <span className='title'>Book Search</span>
              <input type='search' id='search' name='search' onKeyUp={listModalHandler} />
              <button className='close-button' onClick={bookModalHandler} >X</button>
            </header>

            {
              listModalActive &&
              <nav className='modal book item-list'>
                {booksSearched.map((book, index) => (
                  <article
                    key={book.isbn}
                    data-isbn={book.isbn}
                    data-title={book.title}
                    data-author={book.author}
                    onClick={bookSelectedHandler} 
                    className={`list-item ${bookActiveIndex === index ? 'active' : ''}`} >
                    <img src={book.image} alt={book.title} />
                    <div className='info' >
                      <div className='title' >
                        {book.title}
                      </div>
                      <div className='author' >
                        {book.author}<br/>
                      </div>
                      <div className='pubdate'>
                        {book.pubdate.substring(0,4)}
                      </div>
                    </div>
                  </article>
                ))}

              </nav>
            }
          </section>
        </div>
      }
    </div>
  )
}

export default Book