import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

const GnB = () => {
    const navigate = useNavigate();
    const [member, setMember] = useState('');

    // load member info logged in
    useEffect(() => {
        if (localStorage.getItem("access_token") === undefined || localStorage.getItem("access_token") === null){
            navigate('/login')
            return
        }

        setMember({
            id: localStorage.getItem("nickname"),
            name: localStorage.getItem("name"),
            social: localStorage.getItem("social")
        })
    }, [navigate]);

    const logoutHandler = () => {
        localStorage.removeItem("access_token")
        navigate('/login')
    }

    return (
        <header className="gnb">
            <button type="button" onClick={logoutHandler} >{member.name}</button>
        </header>
    );
};

export default GnB;