import { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { rapi } from '../utils/constants';

const ArticlePublished = ({ tag, onModal }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [articles, setArticles] = useState([]);
  const [pagination, setPagination] = useState({});

  // load articles unpublished
  useEffect(() => {
    const keywordEl = document.querySelector(`#keyword`)
    keywordEl.value = sessionStorage.getItem('keyword')

    requestArticles()
  }, [tag]);

  const pageHandler = (e) => {
    sessionStorage.setItem('page', e.target.dataset.page)
    requestArticles()
  }

  const requestArticles = () => {
    const paging = {
      page: sessionStorage.getItem('page') ?? 1,
      tag: sessionStorage.getItem('tagSelected') ?? '%',
      keyword: sessionStorage.getItem('keyword') ?? '%'
    }

    if (paging.tag === '전체글') paging.tag = '%'

    rapi.get(`/article/list?page=${paging.page}&pageSize=10&tag=${paging.tag.length < 1 ? '%' : paging.tag}&keyword=${paging.keyword.length < 1 ? '%' : paging.keyword}&pageIndexSize=5`)
      .then(res => {
        if (res.status !== 200) {
          console.log(`${res.status} : ${res.statusText}`);
        }

        setArticles(res.data.list);
        setPagination({
          total: res.data.total,
          page: res.data.page,
          tag: res.data.tag,
          keyword: res.data.keyword
        });

      }).catch(error => {
        console.log(error);
      });
  };

  const RenderPagination = () => {
    const total = pagination.total ?? 0;
    const page = pagination.page ?? 0;

    const pageSize = 10;
    const indexSize = 5;
    const indexLast = Math.ceil(total / pageSize);

    const indexBegin = Math.max(Math.floor((page - 1) / indexSize) * indexSize + 1, 1);
    const indexEnd = Math.min(indexBegin + indexSize - 1, indexLast);
    const indexPrev = indexBegin === 1 ? -1 : indexBegin - 1;
    const indexNext = indexLast > indexEnd ? indexEnd + 1 : -1;

    const contents = [];
    if (indexPrev !== -1)
      contents.push(<button key={'p'} className='list-item prev' onClick={pageHandler} data-page={indexPrev}>↑</button>);

    for (let i = indexBegin; i < indexEnd + 1; i++) {
      contents.push(<button key={i} className={`list-item ${(page === i ? 'selected' : '')}`} onClick={pageHandler} data-page={i}>{i}</button>);
    }

    if (indexNext !== -1)
      contents.push(<button key={'n'} className='list-item next' onClick={pageHandler} data-page={indexNext}>↓</button>);

    return contents;
  }

  const goToView = (e) => {
    if (id === undefined || e.currentTarget.dataset.id !== id) {
      navigate(`/article/${e.currentTarget.dataset.id}`)
    }
    else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  const keywordChangedHandler = (e) => {
    pagination.keyword = e.target.value;
  }

  const submitHandler = (e) => {
    e.preventDefault()

    const inputEl = document.querySelector(`#keyword`)
    inputEl.blur()

    sessionStorage.setItem('keyword', inputEl.value)
    sessionStorage.setItem('page', 1)
    requestArticles()
  }

  const closeSectionHandler = () => {
    const modalContainer = document.querySelector(`.tags > .modal-container`)
    modalContainer.classList.add('hide')
  }

  return (
    <section className={`article-list published ${onModal ? 'modal' : ''}`} >
      <header>
        <span className='title'>{sessionStorage.getItem('tagSelected') ?? '전체글'}</span>
        <form onSubmit={submitHandler}>
          <input type="search" id="keyword" name="keyword" defaultValue={''} value={pagination.keyword} onChange={keywordChangedHandler} size='10' placeholder="keyword" />
        </form>
        <button type='button' onClick={closeSectionHandler} className='close-button' >X</button>
      </header>
      <nav className='list'>
        {articles.map((a) => (
          <article key={a.id} data-id={a.id} onClick={goToView} className={`list-item ${a.id === parseInt(id) ? 'selected' : ''}`} >
            <a href={`/article/${a.id}`} >
              <time dateTime={a.dateOccurred}>{a.dateOccurred.split('T')[0].substring(0, 10).replaceAll('-', '.')}</time>
              <span className='title'>{a.title}</span>
            </a>
          </article>
        ))}
      </nav>
      <aside className='control paging' >
        {RenderPagination()}
      </aside>
    </section>
  )
}

export default ArticlePublished