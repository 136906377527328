import { useRef, useState } from 'react';
import { rapi, paths } from '../utils/constants';

const ArticleImage = ({article, seq, image, date, drop}) => {
  const [captionModal, setCaptionModal] = useState(false)
  const titleRef = useRef('')
  const descRef = useRef('')

  const [positionModal, setPositionModal] = useState(false)
  const paragraphRef = useRef('')
  const alignmentRef = useRef('')

  const ref = useRef(null)

  const representativeHandler = (e) => {
    if(!window.confirm('this will become representative image')) return false;

    const request = {
      bundleID: article,
      imageID: e.target.dataset.id
    }

    rapi.patch(`/image/representative`, request).then(res => {
      if(res.status !== 200){
          console.log(`${res.status} : ${res.statusText}`);
      }

      alert('It became representative image');
  
    }).catch(error => {
        console.log(error);
    });    
  };

  const captionHandler = (e) => {
    setCaptionModal(!captionModal);
  }

  const captionSubmit = (e) => {
    e.preventDefault()

    const request = {
      id: image.id,
      title: titleRef.current.value,
      description: descRef.current.value
    }

    rapi.patch(`/image/caption`, request).then(res => {
      if(res.status !== 200){
          console.log(`${res.status} : ${res.statusText}`);
      }

      image.imageTitle = request.title
      image.imageDesc = request.description
      alert('caption updated')
      setCaptionModal(false)
  
    }).catch(error => {
        console.log(error);
    });

  }

  const positionHandler = (e) => {
    setPositionModal(!positionModal)
  }

  const positionSubmit = (e) => {
    e.preventDefault()

    const request = {
      bundleID: parseInt(article),
      seq: image.seq,
      imageID: image.id,
      paragraph: parseInt(paragraphRef.current.value),
      alignment: alignmentRef.current.value,
    }

    rapi.put('/image/position', request).then(res => {
      if(res.status !== 200){
        console.log(`${res.status} : ${res.statusText}`);
      }

      if (res.data.result === -1){
        alert(`failed to update ${res.data.code}.${res.data.message}`)
        return
      }

      image.seq = request.seq
      image.paragraph = request.paragraph
      image.alignment = request.alignment
      alert('position updated')
      setPositionModal(false)
    })
  }

  return (
    <figure className={image.alignment} ref={ref} >
        <img src={`${paths[image.folderOption]}/${image.folderOption === 30 ? date.substring(0,4) : date.replaceAll('-', '')}/${image.imageName}`} className={image.alignment} alt={image.imageTitle ?? image.imageName } />
        {
          !captionModal &&
          <>
            <figcaption className='title' >{image.imageTitle}</figcaption>
            <figcaption className='detail' >{image.imageDesc}</figcaption>
          </>
        }
        {
          captionModal &&
          <form onSubmit={captionSubmit} >
            <input type="text" id="title" defaultValue={image.imageTitle} ref={titleRef} placeholder="title" />
            <input type="text" id="description" defaultValue={image.imageDesc} ref={descRef} placeholder="description" />
            <button type='submit' onSubmit={captionSubmit} >Update!</button>
            <button type='button' className='info' onClick={captionHandler} >Cancel</button>
          </form>
        }
        {
          positionModal && 
          <form onSubmit={positionSubmit} >
            <fieldset>
              <legend>Position</legend>
              <input type="number" id="position" defaultValue={image.paragraph} ref={paragraphRef} />
            </fieldset>
            <fieldset>
              <legend>Alignment</legend>
              <input type="text" id="alignment" defaultValue={image.alignment} ref={alignmentRef} maxLength="1" />
            </fieldset>
            <button type='submit' onSubmit={positionSubmit}>Update!</button>
            <button type='button' className='info' onClick={positionHandler} >Cancel</button>
          </form>
        }
        <figcaption className="figure-control" >
          <button type='button' data-id={image.id} className="opacity-50" onClick={positionHandler}>Position</button>
          <button type='button' data-id={image.id} className="opacity-50" onClick={captionHandler} >Caption</button>
          <button type='button' className={`representative warning${image.isRepresentative ? '-fill' : ''}`} data-id={image.id} onClick={representativeHandler} >representative</button>
        </figcaption>
    </figure>
  )
}

export default ArticleImage
