import axios from "axios"

export const authapi = axios.create({
    baseURL: 'https://auth2.rudol.net',
    withCredentials: true    
})

export const rapi = axios.create({
    baseURL: 'https://api.rudol.net',
    withCredentials: true
})

export const axiosOptions = {
    baseURL: 'https://api.rudol.net',
    withCredentials: true
}

export const paths = {
    0: 'https://rudol.net/attachment',
    20: 'https://rudol.net/attachmentR2',
    30: 'https://rudol.net/attachmentR3',
}

export const permissionMessages = {
    1: "member 등급 이상의 회원만 볼 수 있어요",
    2: "friend 등급 이상의 회원만 볼 수 있어요",
    255: "비공개: 글쓴이와 관리자만 볼 수 있어요"
}