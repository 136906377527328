import { useNavigate } from "react-router-dom";
import { GnB } from '../components/index.js';
import { lazy, Suspense } from 'react';

const Main = () => {
  const navigate = useNavigate();

  // access_token
  const access_token = localStorage.getItem('access_token');
  if (access_token === null ) navigate('/login');

  const TagList = lazy(() => import('../components/TagList'))
  const ArticleUnpublished = lazy(() => import('../components/ArticleUnpublished'))

  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>} >
          <GnB  />
          <ArticleUnpublished  />
          <TagList count={20} />
      </Suspense>
    </div>
  )
}

export default Main