import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { authapi, rapi } from '../utils/constants';
import styles from '../css/Login.module.css';

const Login = () => {
    const [id, setID] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault()

        authapi.patch('https://auth2.rudol.net/membership/login', {id, password}).then(res => {
            const { access_token, name, nickname, social } = res.data;

            // console.log(res.data);

            localStorage.setItem("access_token", access_token)
            localStorage.setItem("nickname", nickname)
            localStorage.setItem("name", name)
            localStorage.setItem("social", social)

            rapi.defaults.headers.common = {'Authorization': `Bearer ${access_token}`}

            navigate('/');
        }).catch(error => {
            console.log(error);
        });;

    };

    return (
        <form id="login" onSubmit={handleSubmit} className={styles.login} >
            <fieldset className={styles.login}>
                <legend>Login for Admin</legend>
                <input type="text" name="id" value={id} className={styles.loginInput} size="12" placeholder='ID' onChange={(e) => setID(e.target.value)} autoFocus />
                <input type="password" name='password' className={styles.loginInput} size="12" placeholder='password' autoComplete='current-password~' onChange={(e) => setPassword(e.target.value)} />
                <button type="submit" >Login</button>
            </fieldset>
        </form>
    );
};

export default Login;