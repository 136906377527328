import React, { useState } from 'react'
import { rapi } from '../utils/constants';
const ArticleUpdate = ({ article, date }) => {
  // Modal control
  const [modalActive, setModalActive] = useState(false);
  const modalHandler = (e) => {
    if (e.target.dataset.type === 'cancel')
      if (!window.confirm('are you sure?')) return false;
    setModalActive(!modalActive);
  }

  // contents to edit
  const [updating, setUpdating] = useState({ article });
  const articleHandler = (e) => {
    const updated = updating;
    updated[e.target.name] = e.target.value;

    setUpdating(updated);
  }

  // submit
  const submitHandler = (e) => {
    e.preventDefault();

    const request = {
      id: article.id,
      seq: article.seq ?? 0,
      title: updating.title ?? article.title,
      body: updating.body ?? article.body,
      markup: updating.markup ?? article.markup,
      p2r: parseInt(updating.p2r ?? article.p2R),
    };

    if (!article.seq) {
      request.dateOccurred = updating.dateOccurred ?? article.dateOccurred;
      request.p2l = parseInt(updating.p2l ?? article.p2L);
    }

    const endpoint = request.seq === 0 ? '/article' : '/article/secondary';

    rapi.put(endpoint, request).then(res => {
      if (res.status !== 200) {
        console.log(`${res.status} : ${res.statusText}`);
      }

      uploadImages();

    }).catch(error => {
      console.log(error);
    });
  }

  // upload image
  const uploadImages = () => {
    const formData = new FormData();
    const images = document.querySelector(`input[type="file"][multiple]`);

    if (images.length === 0) {
      alert('saved!');
      window.location.reload();
    }

    formData.append(`id`, article.id);
    formData.append(`seq`, article.seq ?? 0);
    formData.append('dateOccurred', article.dateOccurred ?? date);
    for (let i = 0; i < images.files.length; i++) {
      formData.append(`image_${i}`, images.files[i]);
    }

    rapi.defaults.headers.common['Content-Type'] = `multipart/form-data`;
    rapi.post(`/file/images-bulk`, formData).then(res => {
      if (res.status !== 200) {
        console.log(`${res.status} : ${res.statusText}`);
      }

      alert('saved!');
      window.location.reload();
    }).catch(err => { alert(err); });
  }

  return (
    <>
      <div className='control'><button onClick={modalHandler} className="right" >Edit</button></div>
      {modalActive &&
        <div className='modal-container' >
          <form className='modal' onSubmit={submitHandler} >
            {!article.seq &&
              <input type="date" id="dateOccurred" name='dateOccurred' value={updating.dateOccurred} defaultValue={article.dateOccurred} onChange={articleHandler} />
            }
            <input type="text" id="title" name='title' value={updating.title} defaultValue={article.title} placeholder='title' onChange={articleHandler} />
            <textarea id="body" name='body' rows={12} value={updating.body} defaultValue={article.body} onChange={articleHandler} />
            <div className='group' >
              <label htmlFor="markup-option" >Markup Option</label>
              <select id="markup-option" name="markup-option" value={updating.markup} defaultValue={article.markup} onChange={articleHandler}  >
                <option value={0} >Automatic</option>
                <option value={2} >full html</option>
                <option value={3} >html with auto line</option>
              </select>
            </div>
            <div className='group' >
              <label htmlFor="images" >Images</label>
              <input type="file" name='files' id="files" multiple />
            </div>
            <div className='group' >
              <label htmlFor="p2r" >Permission to read</label>
              <select id='p2r' name="p2r" value={updating.p2R} defaultValue={article.p2R} onChange={articleHandler}  >
                <option value={0} >open</option>
                <option value={1} >private : member</option>
                <option value={2} >private : friend</option>
                <option value={255} >close</option>
              </select>
            </div>
            {!article.seq &&
              <div className='group' >
                <label htmlFor="p2l" >Permission to list-up</label>
                <select id="p2l" name="p2l" value={updating.p2L} defaultValue={article.p2L} onChange={articleHandler}  >
                  <option value={0} >open</option>
                  <option value={1} >private : member</option>
                  <option value={2} >private : friend</option>
                  <option value={255} >close</option>
                </select>
              </div>
            }
            <div className='control' >
              <button type="submit" >Save!</button>
              <button type="button" onClick={modalHandler} className="right" data-type='cancel' >Cancel</button>
            </div>
          </form>
        </div>
      }
    </>
  )
}

export default ArticleUpdate