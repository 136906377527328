import { useEffect, useState } from 'react'

export const Toast = ({message}) => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 2000); // hide toast after 3 seconds
  }, [message])

  return (
    <>
      {showToast && (
        <div id='toast'>
          {message}
        </div>
      )}
    </>
  );
}
