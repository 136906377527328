import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Login, Main, ArticleView } from './pages'

const App = () => {
  let windowWidth = window.innerWidth
  window.addEventListener('resize', () => {
    if (windowWidth !== window.innerWidth){
      const modalContainer = document.querySelector('.modal-container')
      modalContainer.classList.add('hide')
      windowWidth = window.innerWidth
    }
  })

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Main />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/article/:id" element={<ArticleView />} />
      </Routes>
    </BrowserRouter>
  )

}

export default App;
